import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import {
  GET_STARTED_MS_BROKER_ROUTE,
  MARKET_SCAN_BROKER_ROUTE,
} from '../constants/routes';
import SEO from '../components/seo';
import LinkButton from '../components/LinkButton';
import Layout from '../components/Layout';
import { AdviserPreFooter } from '../components/PreFooter';
import useMatchBreakpoint from '../hooks/useMatchBreakpoint';
import CurveDownSvg from '../images/layout/curve-down.svg';
import TimeSvg from '../images/adviser/time.svg';
import StarSvg from '../images/adviser/star.svg';
import GridSvg from '../images/adviser/grid.svg';
import HandSvg from '../images/adviser/hand.svg';
import styles from './styles/adviser.module.scss';

const sliderSettings = {
  dots: false,
  autoplay: true,
  fade: true,
  speed: 2000,
  autoplaySpeed: 60000,
  swipeToSlide: false,
  pauseOnHover: false,
};

const benefits = [
  {
    icon: TimeSvg,
    title: 'Save time and effort, get it done online',
    text: 'Let us compare, find you the best cover and sort out the paperwork.',
  },
  {
    icon: StarSvg,
    title: 'Access exclusive covers with better benefits',
    text:
      'Get insurance products from the likes of Ando, NZI, Vero, Chub... that are only accessible via an adviser.',
  },
  {
    icon: GridSvg,
    title: 'Get properly covered with your insurance',
    text:
      'Not sure if you have enough cover or the right cover? We will help guide you.',
  },
  {
    icon: HandSvg,
    title: 'Supported at claim time',
    text: 'We will fight for your claims and get you what you deserve.',
  },
];

const adviser = () => {
  const isMobile = useMatchBreakpoint(576);

  const images = useStaticQuery(
    graphql`
      query {
        cover1: file(relativePath: { eq: "adviser/adviser-1.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cover2: file(relativePath: { eq: "adviser/adviser-2.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cover1Mobile: file(relativePath: { eq: "adviser/adviser-1m.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 576) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        cover2Mobile: file(relativePath: { eq: "adviser/adviser-2m.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 576) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        provider1: file(relativePath: { eq: "adviser/aia.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 36) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        provider2: file(relativePath: { eq: "adviser/nzi.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 41) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        provider3: file(relativePath: { eq: "adviser/ando.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 25) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        provider4: file(relativePath: { eq: "adviser/vero.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 57) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        provider5: file(relativePath: { eq: "adviser/star.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 38) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        provider6: file(relativePath: { eq: "adviser/chubb.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 73) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        provider7: file(relativePath: { eq: "adviser/partners-life.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 115) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        provider8: file(relativePath: { eq: "adviser/cigna.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 80) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `,
  );

  return (
    <Layout
      className={styles.container}
      mini={true}
      appUrl={MARKET_SCAN_BROKER_ROUTE}
      signUpUrl={GET_STARTED_MS_BROKER_ROUTE}
      footerClassName={styles.footer}
    >
      <SEO title="Insurance Adviser" />
      <div className={styles.header}>
        {isMobile ? (
          <Slider className={styles.slider} {...sliderSettings}>
            <Img
              className={styles.coverImgMobile}
              fluid={images.cover1Mobile.childImageSharp.fluid}
            />
            <Img
              className={styles.coverImgMobile}
              fluid={images.cover2Mobile.childImageSharp.fluid}
            />
          </Slider>
        ) : (
          <Slider className={styles.slider} {...sliderSettings}>
            <Img
              className={styles.coverImg}
              fluid={images.cover1.childImageSharp.fluid}
            />
            <Img
              className={styles.coverImg}
              fluid={images.cover2.childImageSharp.fluid}
            />
          </Slider>
        )}
        <div className={styles.title}>
          <div className="container">
            <h1>
              Upgrade your insurance
              <br /> experience <span className={styles.blue}>today</span>
            </h1>
            <p>
              Save time, compare multiple quotes and get properly
              <br /> covered with the help of our licensed advisers.
            </p>
            <LinkButton
              href={GET_STARTED_MS_BROKER_ROUTE}
              background="#4187F5"
              className={styles.blueBtn}
            >
              Sign up for free
            </LinkButton>
          </div>
        </div>
        <div className={styles.providers}>
          <b>Some insurance providers available through our adviser</b>
          <div className={styles.providerContainer}>
            <Img
              className={styles.providerImg}
              fixed={images.provider1.childImageSharp.fixed}
            />
            <Img
              className={styles.providerImg}
              fixed={images.provider2.childImageSharp.fixed}
            />
            <Img
              className={styles.providerImg}
              fixed={images.provider3.childImageSharp.fixed}
            />
            <Img
              className={styles.providerImg}
              fixed={images.provider4.childImageSharp.fixed}
            />
            <Img
              className={styles.providerImg}
              fixed={images.provider5.childImageSharp.fixed}
            />
            <Img
              className={styles.providerImg}
              fixed={images.provider6.childImageSharp.fixed}
            />
            <Img
              className={styles.providerImg}
              fixed={images.provider7.childImageSharp.fixed}
            />
            <Img
              className={styles.providerImg}
              fixed={images.provider8.childImageSharp.fixed}
            />
          </div>
        </div>
        <CurveDownSvg className={styles.curveDown} />
      </div>
      <div className={styles.body}>
        <div className="container">
          <h1>
            With our adviser, you don't
            <br /> have to do insurance alone
          </h1>
          <div className={styles.benefits}>
            {benefits.map(({ icon: Icon, title, text }) => (
              <div key={title} className={styles.benefit}>
                <div className={styles.iconContainer}>
                  <Icon />
                </div>
                <div className={styles.right}>
                  <h2>{title}</h2>
                  <p>{text}</p>
                </div>
              </div>
            ))}
          </div>
          <LinkButton
            href={GET_STARTED_MS_BROKER_ROUTE}
            background="#4187F5"
            className={styles.blueBtn}
          >
            Get started - it’s free
          </LinkButton>
        </div>
        <CurveDownSvg className={styles.curveDown} />
      </div>
      <AdviserPreFooter />
    </Layout>
  );
};

export default adviser;
